const equalProductTitleHeights = () => {
  const teaserTitles = document.querySelectorAll('.teaser--full-image .cmp-teaser__title');

  if (teaserTitles.length > 0) {
    let maxHeight = 0;

    teaserTitles.forEach(title => {
      title.style.minHeight = ''; // Reset height to calculate the correct height
    });

    teaserTitles.forEach(title => {
      maxHeight = Math.max(maxHeight, title.offsetHeight);
    });

    teaserTitles.forEach(title => {
      title.style.minHeight = `${maxHeight}px`;
    });
  }
};

const isDesktopView = () => {
  return window.innerWidth >= 900;
};

const resetTitleHeights = () => {
  const teaserTitles = document.querySelectorAll('.cmp-teaser__title');
  teaserTitles.forEach(title => {
    title.style.minHeight = '';
  });
};

if (isDesktopView()) {
  window.addEventListener('load', equalProductTitleHeights);
}

window.addEventListener('resize', () => {
  if (isDesktopView()) {
    equalProductTitleHeights();
  } else {
    resetTitleHeights();
  }
});
