import { isDesktop } from '../../site/_utils';

const xfHeader = () => {

    function setNavigationGroupsPosition() {
        document.querySelectorAll('.cmp-navigation__item--level-0').forEach((item , index) => {
            const group = item?.querySelector('.cmp-navigation__group') as HTMLElement;
            const allGroupLvl2 = item?.querySelectorAll('.cmp-navigation__group--level-2');

            if (group) {
                if (isDesktop()) {
                    const navItemLeft = index !== 0 ? item.offsetLeft : item.offsetLeft - 20;
                    const groupHeight = group.offsetHeight - parseFloat(getComputedStyle(group).padding) * 2;
                    const groupWidth = group.offsetWidth;

                    group.style.left = `${navItemLeft}px`;

                    allGroupLvl2?.forEach((item) => {
                        const groupLvl2 = item as HTMLElement;
                        groupLvl2.style.left = `${navItemLeft + groupWidth - 1}px`;
                        groupLvl2.querySelector('ul').style.minHeight = `${groupHeight}px`;
                    });
                } else {
                    allGroupLvl2?.forEach((groupLvl2: HTMLElement) => {
                        groupLvl2.style.left = '0';
                        groupLvl2.querySelector('ul').style.minHeight = '';
                    });
                    group.style.left = '0';
                }
            }
        });
    }

    function toggleNavigationGroupLevel0(isVisible: boolean) {
        document.querySelectorAll<HTMLElement>('.cmp-navigation__item--level-0')
            .forEach(item => {
                item.classList.toggle('cmp-navigation__item--level-0-hide', !isVisible);
            });
        setNavigationGroupsPosition();
    }

    function toggleNavigationGroupLevel1(navigationItem: HTMLElement, isVisible: boolean) {
        const level1Group = navigationItem?.querySelector('.cmp-navigation__group') as HTMLElement;

        if (level1Group) {
            navigationItem.classList.toggle('cmp-navigation__item--level-0-expanded', isVisible);
            level1Group.classList.toggle('cmp-navigation__group--level-1-visible', isVisible);

            const activeElement = document.activeElement;
            const isElemFocused = activeElement && activeElement.matches(':focus-visible');

            if (isVisible && isElemFocused) {
                level1Group.firstElementChild?.querySelector('a')?.focus({ focusVisible: true });
            }
        }
        setNavigationGroupsPosition();
    }

    function toggleNavigationGroupLevel2(navigationItem: HTMLElement, isVisible: boolean) {
        const level2Parent = navigationItem?.closest('.cmp-navigation__item-expandable-level0');
        const level1Items = level2Parent?.querySelectorAll('.cmp-navigation__item-expandable-level1');
        const index = level1Items ? Array.from(level1Items).indexOf(navigationItem) : 0;
        const level2Group = level2Parent?.querySelectorAll('.cmp-navigation__group--level-2')?.[index] as HTMLElement;

        navigationItem?.classList.toggle('cmp-navigation__item--level-1-expanded', isVisible);
        level2Group?.classList.toggle('cmp-navigation__group--level-2-visible', isVisible);

        const activeElement = document.activeElement;
        const isElemFocused = activeElement && activeElement.matches(':focus-visible');

        if (isVisible && isElemFocused) {
            level2Group?.firstElementChild?.querySelector('a, button')?.focus({ focusVisible: true });
        }

        setNavigationGroupsPosition();
    }

    function toggleControlsTitle(title: string, isVisible: boolean = true) {
        const controls = document.querySelector('.header__main-nav-controls') as HTMLElement;
        if (controls) {
            const controlsTitle = controls.querySelector('.header__main-nav-controls--title') as HTMLElement;
            controlsTitle.innerHTML = title;
            if (isVisible) {
                controls.classList.add('header__main-nav-controls-show');
            } else {
                controls.classList.remove('header__main-nav-controls-show');
            }
        }
    }

    function toggleNavigationItemLevel0(navigationItemLevel0: HTMLElement, keepActive: boolean = true) {
        const currentExpandedItemLevel0 = document.querySelector('[level0-expanded]') as HTMLElement;
        const currentExpandedItemLevel1 = document.querySelector('[level1-expanded]') as HTMLElement;
        const isExpanded = navigationItemLevel0?.hasAttribute('level0-expanded');
        const auth = document.querySelector('.header-nav__auth') as HTMLElement;
        document.querySelector('#header-nav-back')?.setAttribute('data-back-to', '0');

        if (currentExpandedItemLevel0 && navigationItemLevel0 !== currentExpandedItemLevel0) {
            setNavigationItemAttributes(navigationItemLevel0, 0, false);
            toggleNavigationItemLevel0(currentExpandedItemLevel0, false);
        }

        if (currentExpandedItemLevel1) {
            setNavigationItemAttributes(currentExpandedItemLevel1, 1, false);
            toggleNavigationItemLevel1(currentExpandedItemLevel1, false);
        }

        const shouldExpand = !isExpanded && keepActive;
        const title = navigationItemLevel0?.querySelector('.cmp-navigation__item-title')?.textContent || '';

        if (!isDesktop()) {
            toggleNavigationGroupLevel0(!shouldExpand);
            toggleNavigationGroupLevel1(navigationItemLevel0, shouldExpand);
            toggleControlsTitle(shouldExpand ? title : '', shouldExpand);
            auth?.classList.toggle('header-nav__auth-show', shouldExpand);
            setNavigationItemAttributes(navigationItemLevel0, 0, shouldExpand && keepActive);
        } else {
            toggleNavigationGroupLevel0(true);
            toggleNavigationGroupLevel1(navigationItemLevel0, shouldExpand);
            setNavigationItemAttributes(navigationItemLevel0, 0, shouldExpand);
        }
    }

    function toggleNavigationItemLevel1(navigationItemLevel1: HTMLElement, keepActive: boolean) {
        const currentExpandedItemLevel1 = document.querySelector('[level1-expanded]') as HTMLElement;
        const expandedItemLevel0 = document.querySelector('[level0-expanded]') as HTMLElement;
        const isExpanded = navigationItemLevel1?.hasAttribute('level1-expanded');

        // Close the current expanded level 1 item
        if (currentExpandedItemLevel1 && (navigationItemLevel1 !== currentExpandedItemLevel1)) {
            setNavigationItemAttributes(currentExpandedItemLevel1, 1, false);
            toggleNavigationItemLevel1(currentExpandedItemLevel1, false);
        }

        if (!isDesktop()) {
            if ((isExpanded && !expandedItemLevel0) || !keepActive) {
                toggleNavigationGroupLevel1(expandedItemLevel0, true);
                toggleNavigationGroupLevel2(navigationItemLevel1, false);
                toggleControlsTitle(expandedItemLevel0?.querySelector('.cmp-navigation__item-title')?.textContent);
                document.querySelector('#header-nav-back')?.setAttribute('data-back-to', '0');
            } else {
                toggleNavigationGroupLevel1(expandedItemLevel0, false);
                toggleNavigationGroupLevel2(navigationItemLevel1, true);
                toggleControlsTitle(navigationItemLevel1?.querySelector('.cmp-navigation__item-title')?.textContent);
                document.querySelector('#header-nav-back')?.setAttribute('data-back-to', '1');
            }

            setNavigationItemAttributes(navigationItemLevel1, 1, keepActive);
        } else {
            toggleNavigationGroupLevel2(navigationItemLevel1, !isExpanded && keepActive);
            setNavigationItemAttributes(navigationItemLevel1, 1, !isExpanded && keepActive);
        }
    }

    function setNavigationItemAttributes(navigationItem: HTMLElement, level: number, isExpanded: boolean) {
        if (!navigationItem) return;

        const levelAttribute = `level${level}-expanded`;
        navigationItem.setAttribute('aria-expanded', String(isExpanded));

        if (isExpanded) {
            navigationItem.setAttribute(levelAttribute, 'true');
        } else {
            navigationItem.removeAttribute(levelAttribute);
        }
    }

    function toggleMainMenu(forceClose: boolean = false) {
        const buttonElement = document.querySelector('#cet-menu-toggle') as HTMLElement;
        const containerElement = document.getElementById('cet-main-navigation');
        const isExpanded = buttonElement.getAttribute('aria-expanded') === 'true';
        const shouldExpand = !isExpanded && !forceClose;

        buttonElement.setAttribute('aria-expanded', String(shouldExpand));
        containerElement.classList.toggle('header__main-nav--expanded', shouldExpand);

        if (isExpanded && !forceClose) resetNavigation();
    }

    function navigationItemsEvents() {
        document.querySelectorAll('.cmp-navigation__item-expandable-level0').forEach((item) => {
            item.addEventListener('click', function(event) {
                const navigationItem = (event.target as HTMLElement).closest('.cmp-navigation__item-expandable-level0') as HTMLElement;
                toggleNavigationItemLevel0(navigationItem);
            });
            item.addEventListener('mouseenter', function(event) {
                if (isDesktop()) {
                    const navigationItem = (event.target as HTMLElement).closest('.cmp-navigation__item-expandable-level0') as HTMLElement;
                    toggleNavigationItemLevel0(navigationItem);
                }
            });
            item.addEventListener('mouseleave', function (event) {
                if (isDesktop()) {
                    const navigationItem = (event.target as HTMLElement).closest('.cmp-navigation__item-expandable-level0') as HTMLElement;
                    toggleNavigationItemLevel0(navigationItem, false);
                }
            });
        });

        document.querySelectorAll('.cmp-navigation__item-expandable-level1').forEach((item) => {
            item.addEventListener('click', function(event) {
                event.preventDefault();
                event.stopPropagation();

                const navigationItem = (event.target as HTMLElement).closest('.cmp-navigation__item-expandable-level1') as HTMLElement;
                toggleNavigationItemLevel1(navigationItem, true);
            });
            item.addEventListener('mouseenter', function (event) {
                if (isDesktop()) {
                    const navigationItem = (event.target as HTMLElement).closest('.cmp-navigation__item-expandable-level1') as HTMLElement;
                    toggleNavigationItemLevel1(navigationItem, true);
                }
            });
            item.addEventListener('mouseleave', function (event) {
                if (isDesktop()) {
                    const navigationItem = (event.target as HTMLElement).closest('.cmp-navigation__item-expandable-level1') as HTMLElement;
                    const level2Groups = navigationItem?.closest('.cmp-navigation__group').parentElement.querySelectorAll('.cmp-navigation__group--level-2');
                    const index = Array.from(navigationItem.parentElement.parentElement.querySelectorAll('.cmp-navigation__item-expandable-level1')).indexOf(navigationItem);
                    const level2Group = level2Groups?.[index] as HTMLElement;

                    //Check if the mouse is over the corresponding level 2 group
                    // @ts-ignore
                    if (!level2Group?.contains(event.relatedTarget)) {
                        toggleNavigationItemLevel1(navigationItem, false);
                    }
                }
            });
        });

        document.querySelectorAll('.cmp-navigation__group--level-2').forEach((group) => {
            group.addEventListener('mouseleave', function(event) {
                if (isDesktop()) {
                    const expandedItem = group.parentElement.querySelector('.cmp-navigation__item--level-1-expanded') as HTMLElement;

                    // @ts-ignore
                    if (!group.contains(event.relatedTarget) && (!expandedItem || !expandedItem.contains(event.relatedTarget))) {
                        toggleNavigationGroupLevel2(expandedItem, false);
                    }
                }
            });
        });

        document.querySelector('#header-nav-back')?.addEventListener('click', function(event) {
            const backTo = document.querySelector('[data-back-to]')?.getAttribute('data-back-to');

            switch (backTo) {
                case '0':
                    const expandedItemLevel0 = document.querySelector('[level0-expanded]') as HTMLElement;
                    if (expandedItemLevel0) {
                        toggleNavigationItemLevel0(expandedItemLevel0, false);
                    }
                    break;
                case '1':
                    const expandedItemLevel1 = document.querySelector('[level1-expanded]') as HTMLElement;
                    if (expandedItemLevel1) {
                        toggleNavigationItemLevel1(expandedItemLevel1, false);
                    }
                    break;
            }
        });

        document.querySelector('#cet-menu-toggle')?.addEventListener('click', function(event) {
            toggleMainMenu();
        });
    }

    function resetNavigation() {
        const expandedItemLevel0 = document.querySelector('[level0-expanded]') as HTMLElement;
        toggleNavigationItemLevel0(expandedItemLevel0, false);

        const expandedItemLevel1 = document.querySelector('[level1-expanded]') as HTMLElement;
        toggleNavigationItemLevel1(expandedItemLevel1, false);

        toggleMainMenu(true);

        toggleControlsTitle('', false);
        setNavigationGroupsPosition();

        const groupLevel0 = document.querySelector('.cmp-navigation__item--level-0 .cmp-navigation__group') as HTMLElement;
        if (groupLevel0) {
            groupLevel0.scrollTop = 0;
        }
    }

    function handleKeyDown(event: KeyboardEvent) {
        // checks what level the active element is to close the corresponding navigation
        if (event.key === 'Escape') {
            const activeElement = document.activeElement as HTMLElement;
            if (activeElement.parentElement.classList.contains('cmp-navigation__item--level-0')) {
                resetNavigation();
            }

            if (activeElement.closest('.cmp-navigation__item--level-1')) {
                const expandedItemLevel0 = document.querySelector('[level0-expanded]') as HTMLElement;
                toggleNavigationItemLevel0(expandedItemLevel0, false);
                expandedItemLevel0.querySelector('a ,button')?.focus({ focusVisible: true });
            }

            if (activeElement.closest('.cmp-navigation__item--level-2')) {
                const expandedItemLevel1 = document.querySelector('[level1-expanded]') as HTMLElement;
                toggleNavigationItemLevel1(expandedItemLevel1, false);
                expandedItemLevel1.querySelector('a ,button')?.focus({ focusVisible: true });
            }
        }
    }

    function onDocumentReady() {
        navigationItemsEvents();
        document.addEventListener('keydown', handleKeyDown);
    }

    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    }

    window.addEventListener("load", setNavigationGroupsPosition);
    window.addEventListener('resize', resetNavigation);
};

export default xfHeader;
